/**
 * A custom hook to interact with localStorage, providing a way to retrieve, update, and reset typed data associated with a given key.
 *
 * @template T - The expected type of the data stored in localStorage.
 *
 * @param {string} key - The key used to access the data in localStorage.
 *
 * @returns {[T | null, (newData: T) => void, () => void]} An array containing:
 *  - `data` {T | null}: The parsed value from localStorage for the given key, or `null` if not found.
 *  - `setData` {(newData: T) => void}: A function to update the value in localStorage.
 *  - `resetData` {() => void}: A function to remove the value associated with the key from localStorage.
 *
 * @example
 * // Using the hook with a specific type
 * const [user, setUser, resetUser] = useLocalStorage<{ name: string; age: number }>('user');
 *
 * // Retrieve data
 * console.log(user?.name); // Access stored data
 *
 * // Update data
 * setUser({ name: 'John Doe', age: 30 });
 *
 * // Reset data
 * resetUser();
 */
export function useLocalStorage(key) {
    // Retrieve a stringified version of the data from localStorage. default to null if not found.
    const stringfyData = localStorage.getItem(key);
    // Retrieve and parse data from localStorage; default to null if not found.
    const data = stringfyData ? JSON.parse(stringfyData) : null;
    // Set a new value to data from localStorage.
    function setData(newData) {
        const stringfyNewData = JSON.stringify(newData);
        localStorage.setItem(key, stringfyNewData);
    }
    // Resets the local storage data related to the key provided.
    function resetData() {
        localStorage.removeItem(key);
    }
    return [data, setData, resetData];
}
